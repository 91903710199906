// Imports
// ------
import { useState, useEffect } from 'react';

// Component
// ------
function useLabelPicker(slug) {
	// NOTE • States
	const [label, setLabel] = useState('Explore more');

	// NOTE • Decider
	const getLabel = (data) => {
		if (data === 'contact') {
			setLabel('Get in touch');
		}
		if (data === 'services') {
			setLabel('Explore services');
		}
		if (data === 'news') {
			setLabel('Read more');
		}
		if (data === 'careers') {
			setLabel('Search careers');
		}
		if (data === 'about') {
			setLabel('Learn more');
		}
	};

	// NOTE • Onload run.
	useEffect(() => {
		getLabel(slug);
	});

	return label;
}

export default useLabelPicker;
