// Imports
// ------
import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

// Styles
// ------
import { Jacket, Content } from './styles';

// Component
// ------
gsap.registerPlugin(ScrollTrigger);

function AnimatedText({ text }) {
	// NOTE • Breakpoints
	const bp = useBreakpoint();

	// NOTE • Refs
	const target = useRef();
	const trigger = useRef();

	// NOTE • Onload - Animate Scroll
	useEffect(() => {
		let check;

		const triggerCheck = () => {
			check = `-${target.current.offsetWidth}`;
		};

		const timer = setTimeout(() => {
			gsap.to(target.current, {
				x: check,
				ease: 'none',
				scrollTrigger: {
					trigger: trigger.current,
					start: '20% 100%',
					end: '60% 0%',
					scrub: 0.5,
					markers: false,
				},
			});
		}, 250);

		triggerCheck();
		window.addEventListener('resize', triggerCheck);

		return () => {
			clearTimeout(timer);
			window.removeEventListener('resize', triggerCheck);
		};
	}, []);

	return (
		<Jacket pad ref={trigger}>
			<Content ref={target}>{text}</Content>
		</Jacket>
	);
}

export default AnimatedText;
