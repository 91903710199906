// Imports
// ------
import styled, { css } from 'styled-components';
import { breakup, Section } from '@tackl';

// Exports
// ------
export const Jacket = styled(Section)(
	(props) => css`
		overflow: hidden;
		position: relative;
		z-index: 2;
	`
);

export const Content = styled.span(
	(props) => css`
		${props.theme.grad(90, props.theme.bc1, props.theme.bc2)}

		display: inline-block;
		width: max-content;

		font-size: clamp(7.2rem, 20vw, 20rem);
		font-family: ${props.theme.heading};
		font-weight: ${props.theme.reg};
		line-height: 1.2;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;

		transform: translateX(100%);
		will-change: transform;
	`
);
